<script lang="ts" setup>
import { last } from 'lodash'
import Cell from '~/grid/Cell'
import { getDoubleCheckIcon } from '~/helpers/UtilsHelper'
import { JInputData } from '~/models/report/jInputData'
import { gridStore } from '~/store/grid'
import { usersStore } from '~/store/users'

const props = defineProps<{
  inputData: JInputData | null | undefined
  cell: Cell | undefined
  isFromModal: boolean
  isHistory: boolean
}>()

const state = reactive({
  openAvatar: false,
  inputData: props.inputData,
  cell: props.cell,
  toast: {} as any,
})

state.toast = inject('toast')

const toastAnotherOperator = () => {
  state.toast.content = 'report.double_check_toast'
  state.toast.status = 'information'
  state.toast.show = true
}

watch(
  () => props.inputData,
  (newValue) => {
    if (!newValue) return

    state.inputData = newValue
  },
  { deep: true, immediate: true },
)

watch(
  () => props.cell,
  (newValue) => {
    if (!newValue) return

    state.cell = newValue
  },
  { deep: true },
)

const lastDoubleCheck = computed(() => {
  return last(props.inputData?.double_check)
})

const handleDoubleCheckModal = () => {
  const { updated_by } = state.inputData || {}
  const currentUserId = usersStore().user?.id

  if (props.isHistory) return

  if (updated_by === currentUserId) {
    toastAnotherOperator()
    return
  }
  gridStore().setDoubleCheckModalCell(state.cell ?? null)
}
</script>

<template>
  <div
    v-if="state.cell?.step?.double_check && state.inputData && !isFromModal"
    v-can.any="['read-report', 'create-report']"
    class="absolute left-1 top-1"
    data-cy="double-check-button"
    @click.stop="handleDoubleCheckModal"
  >
    <component
      :is="getDoubleCheckIcon(lastDoubleCheck?.result)"
      class="h-5 w-5 cursor-pointer"
      :class="{
        'text-duskblue-500': lastDoubleCheck?.result,
        'text-red-500': lastDoubleCheck?.result === false,
        'text-grey-500': !lastDoubleCheck?.checked_at,
      }"
    />
  </div>
</template>

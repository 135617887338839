import { i18n } from '~/modules/i18n'
import { rolesStore } from '~/store/roles'

const { t } = i18n.global

export enum SettingsType {
  // default = 'default',
  document_category = 'document_category',
  routine_category = 'routine_category',
  tolerance = 'tolerance',
  team = 'team',
  media_library_tag = 'media_library_tag',
  reasons = 'reasons',
  lookup_tables = 'lookup_tables',
  report_status = 'report_status',
  template_alerts_message = 'template_alerts_message',
  template_input_modification_message = 'template_input_modification_message',
  group = 'group',
}

export class Settings {
  static addedAttributes: Array<string> = ['_type']
  static hiddenAttributes: Array<string> = [
    'id',
    'client_id',
    'site_id',
    'deleted_at',
    'values',
  ]

  public id?: string = ''
  public type: SettingsType = SettingsType.default
  public value: any = ''
  public client_id = ''
  public site_id = ''
  public deleted_at = null
  public values = []

  constructor(data: object) {
    for (const key in data) this[key] = data[key]
  }

  get _type() {
    return t(`settings.${this.type}`)
  }

  public toJson(): object {
    return {
      id: this.id,
      type: this.type,
      value: this.value,
      deleted_at: this.deleted_at,
    }
  }

  public static fromCollection(data: Array<object>) {
    return data.map((item) => {
      return new this({
        ...item,
        values: data
          .map((row) => row?.value)
          .filter((value) => value !== item.value),
      })
    })
  }

  public editable() {
    return [{ attribute: 'value', type: 'text', required: true, unique: true }]
  }

  public validate() {
    if (!this.value || this.value.trim() === '') return false

    return true
  }

  public static getHeader(): object {
    const removedAttributes = this.addedAttributes
      .map((item) => item.replace('_', ''))
      .concat(this.hiddenAttributes)

    return [...this.addedAttributes, ...Object.keys(new this())]
      .filter(
        (value) =>
          !removedAttributes.includes(value) && !value.includes('type'),
      )
      .map((item) => {
        return {
          key: item,
          value: t(`settings.${item}`),
        }
      })
  }
}

export class Team extends Settings {
  type: SettingsType = SettingsType.team
  starting_time = 0
  ending_time = 0

  constructor(data: object) {
    super(data)

    this.starting_time = data?.starting_time || this.starting_time
    this.ending_time = data?.ending_time || this.ending_time
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type || null,
      starting_time: this.starting_time || null,
      ending_time: this.ending_time || null,
    }
  }

  public editable() {
    return [
      ...super.editable(),
      { attribute: 'starting_time', type: 'number' },
      { attribute: 'ending_time', type: 'number' },
    ]
  }
}

export class MediaLibraryTag extends Settings {
  type: SettingsType = SettingsType.media_library_tag

  constructor(data: object) {
    super(data)
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type || null,
    }
  }

  public editable() {
    return [...super.editable()]
  }
}

export class Group extends Settings {
  type: SettingsType = SettingsType.group
  name = ''
  created_at = ''
  usersIds: string[] = []

  constructor(data: object) {
    super(data)
    this.name = data?.name || this.name
    this.created_at = data?.created_at || this.created_at
    this.usersIds = data?.usersIds || this.usersIds
  }

  public toJson() {
    return {
      ...super.toJson(),
      name: this.name || null,
      created_at: this.created_at || null,
      usersIds: this.usersIds || null,
    }
  }

  public editable() {
    return [
      { attribute: 'name', type: 'text', required: true },
      { attribute: 'usersIds', type: 'users_list' },
    ]
  }

  public validate() {
    if (!this.name || this.name.trim() === '') return false

    return true
  }
}

export class Reasons extends Settings {
  type: SettingsType = SettingsType.reasons

  constructor(data: object) {
    super(data)
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type || null,
    }
  }

  public editable() {
    return [...super.editable()]
  }
}

export class Tolerance extends Settings {
  type: SettingsType = SettingsType.tolerance

  constructor(data: object) {
    super(data)
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type,
    }
  }
}

export class DocumentCategory extends Settings {
  type: SettingsType = SettingsType.document_category
  order = 0
  blocker = false
  justify_ko = false
  mandatory_finish = false
  roles_access = []

  constructor(data: object) {
    super(data)
    this.order = data?.order || 0
    this.blocker = data?.blocker || false
    this.justify_ko = data?.justify_ko || false
    this.mandatory_finish = data?.mandatory_finish || false
    this.roles_access = data?.roles_access || []
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type,
      order: this.order,
      blocker: this.blocker,
      justify_ko: this.justify_ko,
      mandatory_finish: this.mandatory_finish,
      roles_access: this.roles_access,
    }
  }

  public editable() {
    return [
      ...super.editable(),
      { attribute: 'mandatory_finish', type: 'checkbox' },
      {
        attribute: 'roles_access',
        type: 'select-multiple',
        options: rolesStore().availableRoles.map((e) => ({
          label: e.name,
          value: e.id,
        })),
      },
      { attribute: 'blocker', type: 'checkbox' },
      { attribute: 'justify_ko', type: 'checkbox' },
      { attribute: 'order', type: 'number' },
    ]
  }
}

export class RoutineCategory extends Settings {
  type: SettingsType = SettingsType.routine_category
  order = 0
  roles_access = []

  constructor(data: object) {
    super(data)
    this.order = data?.order || 0
    this.roles_access = data?.roles_access || []
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type,
      order: this.order,
      roles_access: this.roles_access,
    }
  }

  public editable() {
    return [
      ...super.editable(),
      { attribute: 'mandatory_finish', type: 'checkbox' },
      {
        attribute: 'roles_access',
        type: 'select-multiple',
        options: rolesStore().availableRoles.map((e) => ({
          label: e.name,
          value: e.id,
        })),
      },
      { attribute: 'blocker', type: 'checkbox' },
      { attribute: 'justify_ko', type: 'checkbox' },
      { attribute: 'order', type: 'number' },
    ]
  }
}

export const colorOptions = [
  { color: 'Gris Clair', hex: '#F1F0F1' },
  { color: 'Gris', hex: '#E4E2E0' },
  { color: 'Marron', hex: '#ECE0DB' },
  { color: 'Orange', hex: '#F6DFCC' },
  { color: 'Jaune', hex: '#F9EDCB' },
  { color: 'Vert', hex: '#DEECDC' },
  { color: 'Bleu ', hex: '#D6E4ED' },
  { color: 'Violet', hex: '#E6DFED' },
  { color: 'Rose', hex: '#F1E0E9' },
  { color: 'Rouge ', hex: '#F1F0F1' },
].map((item) => {
  return {
    value: item.color,
    key: item.hex,
  }
})

export class DocumentStatus extends Settings {
  type: SettingsType = SettingsType.report_status
  color = ''

  constructor(data: object) {
    super(data)
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type,
      color: this.color,
    }
  }

  public editable() {
    return [
      ...super.editable(),
      {
        attribute: 'color',
        type: 'select',
        options: colorOptions,
      },
    ]
  }
}

export class LookupTable extends Settings {
  type: SettingsType = SettingsType.lookup_tables

  constructor(data: object) {
    super(data)
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type,
    }
  }

  public editable() {
    return [...super.editable(), { attribute: 'db_type', type: 'string' }]
  }
}

export class TemplateAlertMessage extends Settings {
  type: SettingsType = SettingsType.template_alerts_message
  // message = ''

  constructor(data: object) {
    super(data)

    // this.message = data?.message || ''
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type,
      // message: this.message,
    }
  }

  public editable() {
    return [
      ...super.editable(),
      // { attribute: 'message', type: 'text' },
    ]
  }
}

export class TemplateInputModificationMessage extends Settings {
  type: SettingsType = SettingsType.template_input_modification_message
  // message = ''

  constructor(data: object) {
    super(data)

    /// this.message = data?.message || ''
  }

  public toJson() {
    return {
      ...super.toJson(),
      type: this.type,
      // message: this.message,
    }
  }

  public editable() {
    return [
      ...super.editable(),
      // { attribute: 'message', type: 'text' },
    ]
  }
}

export const getClassType = (name: string) => {
  const types = [
    { name: SettingsType.team, type: Team },
    { name: SettingsType.media_library_tag, type: MediaLibraryTag },
    { name: SettingsType.tolerance, type: Tolerance },
    { name: SettingsType.document_category, type: DocumentCategory },
    { name: SettingsType.routine_category, type: RoutineCategory },
    { name: SettingsType.report_status, type: DocumentStatus },
    { name: SettingsType.lookup_tables, type: LookupTable },
    { name: SettingsType.reasons, type: Reasons },
    { name: SettingsType.template_alerts_message, type: TemplateAlertMessage },
    {
      name: SettingsType.template_input_modification_message,
      type: TemplateInputModificationMessage,
    },
    { name: SettingsType.group, type: Group },
  ]

  return types.find((e) => e.name === name)?.type || Settings
}

<script lang="ts" setup>
import { format } from 'date-fns'
import type TimeCell from '~/grid/TimeCell'
import { Icons } from '~/models/Icon'
import { gridStore } from '~/store/grid'
import { siteStore } from '~/store/site'
import type Grid from '~/grid/Grid'
import DoubleCheck from './DoubleCheck.vue'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  isCleared: {
    type: Boolean,
    default: false,
    required: false,
  },
  isError: {
    type: Boolean,
    default: false,
    required: false,
  },
  isFromModal: {
    type: Boolean,
    default: false,
    required: false,
  },
})
const emit = defineEmits<{
  (e: 'setNewValue', val: any): void
}>()
const regex = /^[0-9]*$/
const minInput = ref(null)
const hourInput = ref(null)

const state = reactive({
  isEnteringMinutes: false,
  colId: props.params?.column?.colDef?.colId || props.params?.answer?.col_id,
  refName: `cell_inactive_div_${props.params?.colDef?.colId}_${props?.params?.rowIndex}`,
  inputName: `cell_input_${props.params?.colDef?.colId}_${props?.params?.rowIndex}`,
  cell: null as any | null,
  rowIndex:
    props.params?.node?.data?.index !== undefined
      ? props.params?.node.data?.index
      : props?.params?.rowIndex,
  colIndex:
    props.params?.colDef?.index -
    props.params?.data?.details?.initialShiftIndex,
})

const showHistoryIcon = siteStore().getFlag('history')

const grid = computed<Grid>(() => {
  if (props.params?.colDef?.reportId) {
    return gridStore(String(props.params?.colDef?.reportId))?.getGrid
  }
  return gridStore().getGrid
})

const cell = computed(() => {
  return grid.value
    ?.getCellByIndex(state.rowIndex, state.colIndex)
    ?.initializeActivationState(props.params) as TimeCell
})

const latestInputData = computed(() => {
  return cell?.value?.getLatestInputData()
})

const isActivated = computed(() => {
  return cell?.value?.isActivated ?? true
})

const activateCell = () => {
  cell.value?.setIsActivated(true)
}

watch(
  cell,
  (newCell) => {
    if (newCell && !props.isFromModal) {
      state.cell = newCell
    } else {
      state.cell = {
        hour: props?.isCleared
          ? null
          : props?.params?.answer?.value?.split(':')[0],
        minutes: props?.isCleared
          ? null
          : props.params?.answer?.value?.split(':')[1],
      }
    }
  },
  { immediate: true }, // Run the watcher immediately with the current value
)

watch(
  () => state.cell?.minutes,
  (newValue, oldValue) => {
    state.isEnteringMinutes = false
    if (!state.cell || !state.cell.setMinutes) return

    if (newValue === '' || newValue === undefined) {
      state.cell.setMinutes('')
      return
    }

    if (regex.test(newValue)) {
      let newMinutes = Number.parseInt(newValue, 10)
      newMinutes = Math.min(59, Math.max(0, newMinutes)) // Ensure minutes are within 0-59

      state.cell.setMinutes(newMinutes.toString().padStart(2, '0'))
    } else {
      // Revert to old value if new value is invalid
      state.cell.setMinutes(oldValue)
    }
  },
  { immediate: true }, // Run the watcher immediately with the current value
)

watch(
  () => state.cell?.hour,
  (newValue) => {
    if (Number(newValue) > 9) {
      state.isEnteringMinutes = true
      minInput.value?.select()
    }
  },
)

const onChange = () => {
  if (!state.cell || state.isEnteringMinutes) return

  const colId = props.params?.column?.colId

  // Logic to handle the change of time in the cell
  if (state.cell?.hour || state.cell?.minutes) {
    if (!state.cell?.hour && state.cell?.minutes) state.cell?.setHour('00') // Set default hour if not provided

    if (!state.cell?.minutes && state.cell?.hour) state.cell?.setMinutes('00') // Set default minutes if not provided

    const newTimeValue = `${state.cell?.hour}:${state.cell?.minutes}`
    emit('setNewValue', newTimeValue)

    !props.isCleared && props.params.node.setDataValue(colId, newTimeValue)
  } else {
    emit('setNewValue', undefined)
    !props.isCleared && props.params.node.setDataValue(colId, undefined)
  }
  state.isEnteringMinutes = false
}

const route = useRoute()

const isHistory = computed(() => route.name === 'reports-reportId')

const isEditable = computed(() => {
  return props.isCleared || cell?.value?.isEditable(props.params)
})

const onFocusHour = () => {
  if (hourInput?.value) hourInput.value.select()
}

const onFocusMinutes = () => {
  if (minInput?.value) minInput.value.select()
}

const history = computed(() => {
  return cell?.value?.getHistoryTooltip()
})
</script>

<template>
  <div v-if="!cell?.step?.isHidden">
    <div v-if="isActivated">
      <DoubleCheck
        :input-data="latestInputData"
        :cell="cell"
        :is-from-modal="props.isFromModal"
        :is-history="cell?.isHistory"
      />
      <div
        v-if="
          (cell?.hasHistory && showHistoryIcon && !props.isFromModal) ||
          (latestInputData?.reason && !props.isFromModal)
        "
        v-can.any="['read-report', 'create-report']"
        :tooltip="grid.onExport ? history : null"
        class="absolute right-1 top-1"
        data-cy="history-button"
        @click.stop="gridStore().setHistoryModalCell(cell ?? null)"
      >
        <component
          :is="Icons.RECENTLY_VIEWED"
          class="h-5 w-5 cursor-pointer text-grey-500"
        />
      </div>
      <div
        v-if="latestInputData?.updated_by && !props.isFromModal"
        v-can.any="['read-report', 'create-report']"
        class="absolute bottom-1 right-1 flex flex-col-reverse items-center pb-2"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[latestInputData?.updated_by]"
          :update-date="latestInputData?.update_date"
          :hide-full-name="!grid?.onExport"
          display-amount
          is-enable-modal
        />

        <span
          v-if="latestInputData?.update_date && grid?.onExport"
          class="text-center text-sm text-gray-600"
          >{{
            format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
          }}</span
        >
      </div>
      <div
        v-if="!grid.onExport"
        :class="[
          `_data-cy-column-${state.colIndex}`,
          `_data-cy-row-${state.rowIndex}`,
          {
            'text-gray-600': !state.cell?.hour && !state.cell?.minutes,
            'text-black': state.cell?.hour || state.cell?.minutes,
            error: props.isError,
          },
        ]"
        :data-cy-column="state.colIndex"
        class="_data-cy-input-type-time flex items-center"
      >
        <input
          :id="state.inputName"
          ref="hourInput"
          v-model="state.cell.hour"
          :disabled="isHistory || !isEditable"
          class="w-[3ch] bg-transparent placeholder-gray-600 placeholder-opacity-100"
          maxlength="2"
          min="0"
          placeholder="--"
          step="1"
          style="z-index: 45; text-align: right"
          type="text"
          @blur="onChange"
          @focus="onFocusHour"
        />:<input
          ref="minInput"
          v-model="state.cell.minutes"
          :disabled="isHistory || !isEditable"
          class="w-[3ch] bg-transparent placeholder-gray-600 placeholder-opacity-100"
          max="59"
          min="0"
          placeholder="--"
          step="1"
          type="text"
          @blur="onChange"
          @focus="onFocusMinutes"
        />
      </div>
      <div v-else>{{ cell.hour }}:{{ cell.minutes }}</div>
    </div>
    <div
      v-else
      class="flex items-center justify-center self-center rounded bg-[#CBCCCD] px-2 py-1.5 text-[#FFFFFF]"
      @click="activateCell()"
    >
      N/A
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  @apply border border-solid border-red-500;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>

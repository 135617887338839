<script setup lang="ts">
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { createPopper } from '@popperjs/core'
import { format } from 'date-fns'
import { capitalizeFirstLetter, truncateString } from '~/utils/formatter'
import { Icons } from '~/models/Icon'
import { gridStore } from '~/store/grid'
import { siteStore } from '~/store/site'
import type ListCell from '~/grid/ListCell'
import type Grid from '~/grid/Grid'
import DoubleCheck from './DoubleCheck.vue'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  isCleared: {
    type: Boolean,
    default: false,
    required: false,
  },
  optionsToShow: {
    type: Number,
    default: null,
    required: false,
  },
  isError: {
    type: Boolean,
    default: false,
    required: false,
  },
  isFromModal: {
    type: Boolean,
    default: false,
    required: false,
  },
  isHistory: {
    type: Boolean,
    default: false,
    required: false,
  },
  isTooltipDown: {
    type: Boolean,
    default: false,
    required: false,
  },
})
const emit = defineEmits<{
  (e: 'setNewValue', val: any): void
}>()

const NUMBER_OF_OPTIONS_TO_SHOW = props.optionsToShow ? props.optionsToShow : 2

const state = reactive({
  options: [],
  searched: '',
  refName: `cell_inactive_div_${props.params?.colDef?.index}_${props?.params?.rowIndex}`,
  rowIndex:
    props.params?.node?.data?.index !== undefined
      ? props.params?.node.data?.index
      : props?.params?.rowIndex,
  colIndex:
    props?.params?.colDef?.index -
    props.params?.data?.details?.initialShiftIndex,
})

const grid = computed<Grid>(() => {
  if (props.params?.colDef?.reportId) {
    return gridStore(String(props.params?.colDef?.reportId))?.getGrid
  }
  return gridStore().getGrid
})

const cell = computed<ListCell>(() => {
  return grid.value
    ?.getCellByIndex(state.rowIndex, state.colIndex)
    ?.initializeActivationState(props.params)
})

const valSelect = computed({
  get: () => {
    if (isInHistory.value) return props.params?.answer?.value

    if (!props.isCleared && props.params.answer?.value) {
      cell.value?.selectOption(props.params.answer?.value)
      return props.params.answer?.value
    }

    if (props.isHistory) return cell?.value?.selectedValues
    return cell?.value?.selectedValues
  },
  set: (value) => {
    cell.value?.selectOption(value)
  },
})

const isHistory = computed(() => {
  return cell?.value?.isHistory
})

const isInHistory = computed(() => {
  return props.params?.isHistory
})
const showHistoryIcon = siteStore().getFlag('history')

const isEditable = computed(() => {
  return props.isCleared || cell?.value?.isEditable(props.params)
})

const isMultiple = computed(() => {
  return cell?.value?.isMultiple
})

const options = computed(() => {
  return cell?.value?.options
})

const updateData = () => {
  emit('setNewValue', valSelect.value)
  props.params?.node?.setDataValue(props.params?.column?.colId, valSelect.value)
}

const hasSelectedField = (value: string) => {
  return valSelect?.value?.includes(value)
}

const isElementVisible = (value: string) => {
  if (!isMultiple.value && !isInHistory.value) return true
  if (!Array.isArray(valSelect.value)) return true
  return (
    valSelect.value?.findIndex((item) => item === value) <
    NUMBER_OF_OPTIONS_TO_SHOW
  )
}

const isCountElementVisible = (value: string) => {
  if (!isMultiple?.value && !isInHistory.value) return false
  if (!Array.isArray(valSelect.value)) return false

  const index = valSelect.value?.findIndex((item) => item === value)
  return (
    index === NUMBER_OF_OPTIONS_TO_SHOW &&
    valSelect.value?.length > NUMBER_OF_OPTIONS_TO_SHOW
  )
}

const countSelection = () => {
  if (!isMultiple?.value && !isInHistory.value) return
  return `+ ${valSelect.value?.length - NUMBER_OF_OPTIONS_TO_SHOW}`
}

const splitString = (values: Object) => {
  if (values) {
    let result: string | Array<object> = ''
    if (typeof values === 'string') {
      result = capitalizeFirstLetter(values)
    } else {
      const arrayValues = Object.values(values)
        .map((value) => capitalizeFirstLetter(value).replaceAll('-', ' '))
        .join('\n')
      result = arrayValues
    }
    return result
  }
}

const isActivated = computed(() => {
  return cell?.value?.isActivated ?? true
})

const activateCell = () => {
  cell.value?.setIsActivated(isEditable.value)
}

const createNewOption = () => {
  cell?.value?.addOption(state.searched)

  const selectedValues =
    Array.isArray(valSelect.value) && isMultiple.value
      ? [...valSelect.value, state.searched]
      : [state.searched]

  cell?.value?.selectOption(selectedValues)

  props.params?.node?.setDataValue(props.params?.column?.colId, valSelect.value)
}

const onSearch = (string) => {
  state.searched = string
}

const withPopper = (dropdownList, component) => {
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
      {
        name: 'toggleClass',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          component.$el.classList.toggle('drop-up', state.placement === 'top')
        },
      },
    ],
  })
  return () => popper.destroy()
}

const latestInputData = computed(() => {
  return cell?.value?.getLatestInputData()
})

const lastUpdatedBy = computed(() => {
  return latestInputData?.value?.updated_by
    ? [latestInputData?.value?.updated_by]
    : []
})

const history = computed(() => {
  return cell?.value?.getHistoryTooltip()
})

const textValue = computed(() => {
  if (Array.isArray(valSelect.value)) {
    return valSelect.value?.join(', ')
  }
  return valSelect.value
})

const showHistory = computed(() => {
  return cell?.value?.hasHistory && showHistoryIcon
})

watch(
  () => valSelect.value,
  (newValue) => {
    if (!isMultiple.value && !newValue) {
      updateData()
    }
  },
)
</script>

<template>
  <div
    v-if="!cell?.step?.isHidden && grid"
    class="_data-cy-select-list flex h-full flex-1 items-center justify-center"
    :class="[
      {
        '_data-cy-select-unique': !isMultiple,
        '_data-cy-select-multiple': isMultiple,
        '_data-cy-is-history': isHistory,
      },
      `_data-cy-row-${state.rowIndex}`,
    ]"
    select-list
    data-cy="select-list"
    :data-cy-column="state.colIndex"
  >
    <DoubleCheck
      :input-data="latestInputData"
      :cell="cell"
      :is-from-modal="props.isFromModal"
      :is-history="cell?.isHistory"
    />
    <div
      v-if="showHistory && !isFromModal"
      v-can.any="['read-report', 'create-report']"
      :tooltip="grid?.onExport ? history : null"
      class="absolute right-1 top-1"
      data-cy="history-button"
      @click.stop="gridStore().setHistoryModalCell(cell ?? null)"
    >
      <component
        :is="Icons.RECENTLY_VIEWED"
        class="h-5 w-5 cursor-pointer text-grey-500"
      />
    </div>
    <div
      v-if="cell?.latestValue && !isFromModal"
      class="absolute bottom-1 right-1 flex flex-col-reverse items-center pb-2"
      data-cy="avatar-button"
    >
      <AvatarMultiple
        :user-ids="lastUpdatedBy"
        :update-date="latestInputData?.update_date"
        :hide-full-name="!grid?.onExport"
        display-amount
        is-enable-modal
      />
      <span
        v-if="latestInputData?.update_date && grid?.onExport"
        class="text-center text-sm text-gray-600"
        >{{
          format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
        }}</span
      >
    </div>
    <div
      v-if="isActivated"
      class="flex max-h-[64px] flex-1"
      :tooltip="!grid.onExport ? splitString(valSelect) : null"
      :flow="
        (state.rowIndex === 0 &&
          props.params?.data?.nbSteps !== props.params?.data?.id) ||
        $props.isTooltipDown
          ? 'down'
          : 'up'
      "
    >
      <v-select
        v-if="!grid.onExport"
        v-model="valSelect"
        class="options-select"
        data-cy-select="select"
        :class="{
          'w-full': !showHistory,
          'w-95p': showHistory,
          error: props.isError,
        }"
        :multiple="isMultiple"
        :deselect-from-dropdown="true"
        label="label"
        :disabled="isHistory || !isEditable"
        :reduce="(option) => option.value"
        :options="options"
        :no-drop="isHistory || !isEditable"
        :clearable="!isHistory"
        append-to-body
        :calculate-position="withPopper"
        :close-on-select="!isMultiple"
        @search="onSearch"
        @close="updateData"
      >
        <template #no-options>
          <div
            v-if="props.params?.data?.details?.isCustomized"
            class="flex flex-row items-center gap-2 px-1 py-2 text-sm hover:cursor-pointer hover:bg-slate-100"
            @click="createNewOption"
            @keydown.enter="createNewOption"
          >
            <component :is="Icons.ADD" />
            <span>{{ $t('step.create_answer') }}</span>
          </div>
        </template>
        <template #option="{ value, label }">
          <div class="flex justify-between align-middle">
            <div class="flex flex-row">
              <component
                :is="Icons.CHECKBOX_CHECKED"
                v-if="hasSelectedField(value)"
                class="-ml-2 mr-2 text-[#3273f6]"
              />
              <component
                :is="Icons.CHECKBOX"
                v-else
                class="-ml-2 mr-2"
              />
              <label
                class="text-sm text-black"
                for="option"
                >{{ label }}</label
              >
            </div>
          </div>
        </template>
        <template #selected-option-container="{ option }">
          <div
            :style="
              isElementVisible(option.value || option.label)
                ? ''
                : 'display:none'
            "
            class="vs__selected"
          >
            <div class="w-max rounded-md text-sm leading-6">
              <div class="flex w-max flex-1 flex-wrap">
                <span>{{
                  truncateString(option.label, grid.onPdf ? 0 : 10)
                }}</span>
              </div>
            </div>
          </div>
          <div
            :style="
              isCountElementVisible(option.value || option.label)
                ? ''
                : 'display:none'
            "
            class="vs__selected"
          >
            <div class="h-6 rounded-md px-1 text-sm leading-6">
              <div class="flex flex-1 flex-wrap">
                <span :flow="state.rowIndex > 1 ? 'up' : 'down'">
                  {{ countSelection() }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </v-select>
      <div
        v-else
        class="tooltip"
      >
        <p
          class="text-sm"
          :class="grid.onPdf ? 'w-[80px]' : 'w-[120px] truncate'"
        >
          {{ textValue }}
        </p>
        <span
          class="tooltiptext"
          style="z-index: 10000"
          >{{ textValue }}</span
        >
      </div>
    </div>

    <div
      v-else
      :id="state.refName"
      class="flex items-center justify-center self-center rounded bg-[#CBCCCD] px-2 py-1.5 text-[#FFFFFF]"
      @click="activateCell()"
    >
      N/A
    </div>
  </div>
</template>

<style lang="scss">
.w-95p {
  width: 95%;
}

.w-80px {
  width: 80px;
}

.w-120px {
  width: 120px;
}

.options-select {
  & .vs__dropdown-toggle {
    @apply border-gray-200;
  }

  & .vs__selected {
    @apply mb-px font-normal text-black;
    border: none;
    background-color: #eeeeee !important;
  }

  & .vs__dropdown-menu {
    width: fit-content;
    left: 50%;
    transform: translate(-50%, 4px);
    z-index: 99999999;
  }

  & .vs__search {
    @apply text-sm text-gray-400;
  }

  & .vs__dropdown-option--highlight {
    background: #f0f2ff;
    color: black;
  }

  & .vs__dropdown-option {
    white-space: unset !important;
  }

  &.error > div:first-child {
    @apply border border-solid border-red-500;
  }
}

.vs__dropdown-menu {
  width: max-content;
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: -20px;
  z-index: 1000;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>

export class ReportAnswerViewModel {
  stepId = ''
  step_ids = [] as string[]
  inputData = [] as ReportInputDataViewModel[]
  rowIndex = 0
  colIndex = 0
  type = ''
  lastColIndex = 0
  lastSamplingAreas = [] as any[]
  updatedShiftIndex = 0
  steps = [] as any[]
  gridSize = 0

  constructor(object: ReportAnswerViewModel | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export class ReportInputDataViewModel {
  col_id = 0
  row_id = 0
  value = 0
  step_id = ''
  type = ''
  update_date = ''
  updated_by = ''
  reason = ''
  double_check: { result: boolean; checked_at: string; checked_by: string }[] =
    []
  double_check_result = ''
  double_checked_by = ''
  double_checked_at = ''
  is_valid = true

  constructor(object: ReportInputDataViewModel | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export class TaskViewModelToAnswer {
  step_id = ''
  type = ''
  value = 0
  answers = [] as any

  constructor(object: TaskViewModelToAnswer | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export class AqlTags {
  Nb_pièce = ''
  Nb_mini = ''

  constructor(object: TaskViewModelToAnswer | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

export class StepModel {
  last_sampling_areas = ''
  last_targets = ''
  hidden = [] as boolean[]
  disabled = false
  col_ids_to_disable = [] as any
  aql_tags = [] as AqlTags[]

  constructor(object: StepModel | object = {}) {
    for (const key in object) this[key] = object[key]
  }
}

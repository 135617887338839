import _ from 'lodash'
import { useAcl } from '@clabnet/vue-simple-acl'
import { useRoute } from 'vue-router'
import CustomHeader from '~/components/UI/CustomHeader.vue'
import type { JReport } from '~/models/documents/jReport'
import type { JDocument } from '~/models/documents/jDocument'
import type { JStep, JStepLastTargets } from '~/models/documents/jStep'
import type { JInputData } from '~/models/report/jInputData'
import {
  addDefaultHeader,
  addSampling,
  checkIsCellDisabled,
  computeFrequency,
  computeGlobalsampling,
  computeRowHeight,
  computeFormulaSampling,
  computeTypeRepetitionInStep,
  getCellEditorSelector,
  getCellHeight,
  getCellRendererSelector,
  getCellStyle,
  getHeaderComponentParams,
  getSeparator,
  handleTags,
  isEditable,
  refreshSampling,
  replaceComputePlaceholders,
  setDetailsByType,
  setReportRepetition,
  computeSamplingByType,
  getColumnState,
} from '~/services/grid'
import type { ContextScopeViewModel } from '~/viewModels/session/contextScopeViewModel'
import { ReportStatus, RoleGrid } from '~/models/documents/jReport'
import type { JSession } from '~/models/sessions/JSession'
import type { ListOptions } from '~/models/documents/documentSettings'
import {
  DocumentSettingsType,
  RepetitionType,
} from '~/models/documents/documentSettings'
import {
  getFormulaContext,
  setReportStatus,
} from '~/controllers/reports/reportsController'
import { documentSettingsStore } from '~/store/documentSettings'
import { usersStore } from '~/store/users'
import TextCell from '~/grid/TextCell'
import MeasureCell from '~/grid/MeasureCell'
import ListCell from '~/grid/ListCell'
import TimeCell from '~/grid/TimeCell'
import NumberCell from '~/grid/NumberCell'
import PhotoCell from '~/grid/PhotoCell'
import BooleanCell from '~/grid/BooleanCell'
import CheckboxCell from '~/grid/CheckboxCell'
import {
  DisplayColumnType,
  DisplayLineType,
} from '~/models/documents/jDocument'
import { alertsStore } from '~/store/alerts'
import { gridStore } from '~/store/grid'
import loggerHelper from '~/helpers/LoggerHelper'
import type { JSite } from '~/models/sites'
import { siteStore } from '~/store/site'
import {
  validateMandatoryStepFilled,
  validateStepBorderLine,
  validateStepKo,
} from '~/controllers/documents'
import { ColumnState } from '~/utils/report'
import { settingsStore } from '~/store/settings'
import { SettingsType } from '~/models/settings/settings'
import { castInputDataValue } from '~/helpers/UtilsHelper'
import { isUndefinedOrNullOrEmpty } from '~/utils/object'
import CalculatorCell from './CalculatorCell'
import type { Cell } from '~/grid/Cell'
import { StepType } from '~/models/documents/jStep'
import { PDF_CELL_WIDTH } from '~/models/Style'
import {
  getNthColumnDate,
  getReportStartingTime,
  getTimeFrequencyDate,
} from '~/helpers/FrequencyHelper'
import { FrequencyType } from '~/models/documents/jDocument'

const cellClasses = {
  Text: TextCell,
  List: ListCell,
  Boolean: BooleanCell,
  Measure: MeasureCell,
  Checkbox: CheckboxCell,
  Time: TimeCell,
  Number: NumberCell,
  Photo: PhotoCell,
  Calculator: CalculatorCell,
}

export enum CellMode {
  FOCUSING = 'focusing',
  EDITING = 'editing',
  EDITED = 'edited',
}

const acl = useAcl()

class Grid {
  private _masterSession: any
  private _session: JSession
  private _report: JReport
  private _document: JDocument
  private _steps: JStep[]
  private _inputData: JInputData[]
  private _cells: Cell[]
  private _groupedCells: object
  private _columnsDefinition: object[]
  private _rowsDefinition: object[]
  private _hasAttachments: boolean
  private _shiftColumnIndex: number
  private _pinnedColumnIndex: number | null
  private _isHistory: boolean
  private _isJustifyKoEnabled: boolean
  private _highlightedCols: number[]
  private _lastColIndexUsed: number
  private _currentSite: JSite
  private _listOptions: ListOptions[]
  private _columnsPerPage: number
  private _columnsToDisplay: number[]
  //
  private _route = useRoute()
  private _allStepsSamplingAreas = []
  private _role: string
  private _isWorkplaceDoc: boolean
  private _onExport: boolean
  private _onPdf: boolean
  // private _showJustificationModal: boolean

  constructor(
    report: JReport,
    document: JDocument,
    masterSession: any,
    session: JSession,
    isHistory: boolean,
    role: string,
    isWorkplaceDoc = false,
    onExport = false,
    onPdf = false,
  ) {
    if (!report || !document || !session)
      throw new Error('Arguments can not be empty!')

    this._report = report
    this._isHistory = isHistory
    this._document = document
    this._steps = report.steps
    this._lastColIndexUsed = report.last_col_index
    this._inputData = report.inputData ?? []
    this._pinnedColumnIndex = report.pinned_column_index
    this._shiftColumnIndex = 0
    this._masterSession = masterSession
    this._session = session
    this._isJustifyKoEnabled = settingsStore().findSettingById(
      document.category,
    )?.justify_ko
    this._cells = []
    this._rowsDefinition = []
    this._columnsDefinition = []
    this._listOptions = []
    this._highlightedCols = []
    this._role = role
    this._hasAttachments = false
    this._currentSite = siteStore().site || []
    this._isWorkplaceDoc = isWorkplaceDoc
    this._onExport = onExport
    this._onPdf = onPdf
    this._columnsToDisplay = []

    this._columnsPerPage = isHistory
      ? report.gridSize
      : document.is_monocolumn
        ? 1
        : siteStore().site.flags.default_nb_columns
    // this._showJustificationModal = false
  }

  public readonly ragCellClassRules = {
    'rag-red-outer': (params: any) => {
      // TODO: get the value of the cell, get mandatory of row, get the columns definition to get the state of cell
      // The method use negativ logic, return false if the step is valid and true otherwise
      const currentStep = this._steps[params?.node.data.index]

      if (currentStep.hidden[params.colDef.realIndex]) return false

      if (
        checkIsCellDisabled(
          currentStep?.col_ids_to_disable,
          params.colDef.realIndex,
          currentStep.parentFrequency,
          currentStep.disabled,
        )
      )
        return false

      const colIndex = params.colDef.index - params.data?.shiftIndex
      const cell = this.getCellByIndex(params?.node.data.index, colIndex)
      const lastInputData = cell?.getLatestInputData()

      const lastValue = lastInputData?.value
      const headerComponentParams = this._columnsDefinition.find(
        (col) => col.index === Number(params.colDef.index),
      )?.headerComponentParams
      if (
        headerComponentParams?.columnState !== ColumnState.new ||
        (lastValue !== '' && lastValue !== undefined)
      ) {
        if (
          params.colDef.index - this._shiftColumnIndex ===
            this._lastColIndexUsed &&
          !this._isHistory
        )
          return validateStepKo(
            lastValue,
            currentStep,
            colIndex,
            false,
            lastInputData,
          )

        return (
          validateStepKo(
            lastValue,
            currentStep,
            params.colDef.realIndex,
            true,
            lastInputData,
          ) ||
          validateMandatoryStepFilled(
            lastValue,
            currentStep.is_mandatory,
            cell?.initializeActivationState(params)?.isActivated,
          )
        )
      }
    },
    'rag-orange-outer': (params: any) => {
      // for some reason this has to be instanciated here
      const tolerance =
        settingsStore().filterSettings(SettingsType.tolerance)[0]?.value || 0

      const cell = this.getCellByIndex(
        params?.node.data.index,
        params.colDef.index - params.data?.shiftIndex,
      )
      const lastValue = cell?.getLatestInputData()?.value
      const currentStep = this._steps[params?.rowIndex]
      const colIndex = params.colDef.index - params.data?.shiftIndex

      return validateStepBorderLine(lastValue, currentStep, tolerance, colIndex)
    },
    'rag-yellow-bg': (params: any) => {
      const highlightedColsForNotifs = (
        this._route?.query?.highlighted_cols as string
      )
        ?.split(',')
        .map(Number)
        .map((item) => item + this._shiftColumnIndex)

      return highlightedColsForNotifs?.includes(params.column.colDef.colId)
    },
  }

  get document(): JDocument {
    return this._document
  }

  updateGrid(currentPage: number): void {
    if (!this._report)
      throw new Error('Error while building cells, Grid not found')

    this.updateGridBody(currentPage)
  }

  async prepareGrid(onlyKoColumns = false, pageNumber?: number): Promise<void> {
    if (!this._report)
      throw new Error('Error while building cells, Grid not found')

    this.buildGridHeader(onlyKoColumns)
    await this.buildGridBody(onlyKoColumns, pageNumber)
  }

  async computeSamplingForAllSteps(
    steps: any[],
    endColumnIndex: number,
    contexts: ContextScopeViewModel[],
  ): Promise<void> {
    if (steps[0]?.last_sampling_areas?.length) return

    const allFormulasData = steps.flatMap((step) =>
      step.formulasData.map((formula) => ({
        ...formula,
        step_id: step.id,
      })),
    )
    if (!allFormulasData.length) return

    const result = await computeFormulaSampling(
      allFormulasData,
      endColumnIndex,
      contexts,
    )

    // Assign the result to each step
    steps.forEach((step) => {
      const stepResult = result?.[step.id] || { sampling: [], aqlTags: null }
      step.sampling = stepResult.sampling
      step.aql_tags = stepResult.aqlTags
    })
  }

  async prepareSteps(): Promise<this> {
    // map report steps on time
    this._listOptions = documentSettingsStore().filterDocumentSettings(
      DocumentSettingsType.list_options,
    )

    // Collect contexts for each step
    const contexts = this._steps.map((step) =>
      getFormulaContext(this._role, step, this.reportGridSize(), this._session),
    )

    this._steps = this._steps
      .map((step: any) => setReportRepetition(step))
      .sort((a: any, b: any) => (a.num_step > b.num_step ? 1 : -1))
    // Compute sampling for all steps
    await this.computeSamplingForAllSteps(
      this._steps,
      this.reportGridSize(),
      contexts,
    )

    for (let index = 0; index < this._steps.length; index++) {
      let step = this._steps[index]

      // set grid repetitions
      this.stepFrequency(step)
      step = computeTypeRepetitionInStep(step)

      let sampling = []

      if (
        !step.last_sampling_areas?.length ||
        this._role === RoleGrid.preview
      ) {
        const result = computeSamplingByType(step, 0, this.reportGridSize())

        step.aql_tags = result.aqlTags
        sampling = result.sampling
      }

      const samplingAreas =
        Object.keys(step?.last_sampling_areas ?? {}).length !== 0 &&
        this._role !== RoleGrid.preview
          ? [step.last_sampling_areas]
          : [sampling]
      this._allStepsSamplingAreas.push(samplingAreas)
      step.last_sampling_areas = _.last(samplingAreas)

      const details = setDetailsByType(
        step,
        samplingAreas,
        this._shiftColumnIndex,
        step.updatedShiftIndex,
        this._listOptions,
        step.typeRepetition[0] !== RepetitionType.formula,
      )

      step.hidden = !Array.isArray(step.hidden) ? [step.hidden] : step.hidden
      if (
        this._document.displayLineType === DisplayLineType.editable_lines &&
        !step.last_sampling_areas.includes(true)
      ) {
        step.hidden = step.hidden.map(() => true)
      }

      if (this._isHistory)
        step.isHidden = !step.hidden
          ?.slice(0, this.reportGridSize())
          .includes(false)

      details.updatedShiftIndex =
        !isUndefinedOrNullOrEmpty(step.updatedShiftIndex) &&
        step.updatedShiftIndex !== 0
          ? step.updatedShiftIndex
          : this._columnsDefinition.length

      const mappedTags = handleTags(
        documentSettingsStore().filterDocumentSettings(
          DocumentSettingsType.step_tag,
        ),
        step.tags,
      )

      step.mappedTags = mappedTags
      step.details = details

      this._rowsDefinition.push(
        this.buildRow(index, step, mappedTags, details, step.aql_tags),
      )

      this._steps[index] = step
    }

    return this
  }

  stepFrequency(step: JStep): void {
    if (!step.branching?.length) return

    const allConditionalStepIds = step.branching.flatMap(
      (b) => b.action_details?.steps_to_display,
    )
    this._steps = this._steps.map((s) => {
      if (allConditionalStepIds.includes(s.id))
        s.parentFrequency = step.frequency
      return s
    })
  }

  buildGridHeader(onlyKoColumns = false): this {
    this._columnsDefinition = addDefaultHeader(
      this._hasAttachments,
      this._inputData,
      this._onPdf,
      this._report.id,
    )
    this._shiftColumnIndex = this._columnsDefinition.length

    this._highlightedCols =
      this._report?.steps?.flatMap(
        (step) => step.last_targets?.map((e) => e.init_col_id) || [],
      ) || []

    return this
  }

  async buildGridBody(onlyKoColumns = false, pageNumber?: number): this {
    if (!this._report)
      throw new Error('Error while building cells, Grid not found')
    // Init steps
    await this.prepareSteps()

    const groupedInputs = _.groupBy(this._inputData, (input: JInputData) => {
      input.value = castInputDataValue(input)
      return `${input.col_id}-${input.row_id}`
    })

    const mergedSampling = computeGlobalsampling(this._allStepsSamplingAreas)

    this._cells = []
    const frequency = this.document.frequency

    const reportStartingTime = getReportStartingTime(frequency, this.report)

    if (!this._columnsToDisplay?.length)
      this._columnsToDisplay =
        this._document.displayType === DisplayColumnType.editable_columns
          ? mergedSampling.reduce((acc, value, index) => {
              if (value === true) {
                acc.push(index)
              }
              return acc
            }, [] as number[])
          : Array.from({ length: this.reportGridSize() }, (_, i) => i)

    if (pageNumber === undefined)
      pageNumber = Math.floor(
        this._columnsToDisplay.findIndex((e) => e === this.getMaxIndexUsed()) /
          this._columnsPerPage,
      )

    if (pageNumber < 0) {
      pageNumber = 0
      try {
        loggerHelper.logError(
          `pageNumber is negative, setting it to 0, report id: ${this._report?.id}, document id: ${this._document?.id}`,
          {
            reportId: this._report?.id,
            documentId: this._document?.id,
            clientId: usersStore()?.user?.client_id,
          },
        )
      } catch (error) {
        console.error('Error while logging error', error)
      }
    }

    const startColumnIndex = pageNumber * this._columnsPerPage

    let endColumnIndex = this._columnsPerPage + startColumnIndex
    endColumnIndex =
      endColumnIndex > this.reportGridSize()
        ? this.reportGridSize()
        : endColumnIndex

    const columnsToDisplay = this._columnsToDisplay.slice(
      startColumnIndex,
      endColumnIndex,
    )

    for (let i = 0; i < columnsToDisplay.length; i++) {
      const numSac = columnsToDisplay[i]
      const date = new Date(reportStartingTime)
      if (frequency?.enabled && frequency?.every && date) {
        switch (frequency.type) {
          case FrequencyType.minute:
            date.setMinutes(date.getMinutes() + frequency.every * numSac)
            break
          case FrequencyType.hour:
            date.setHours(date.getHours() + frequency.every * numSac)
            break
          case FrequencyType.day:
            date.setDate(date.getDate() + frequency.every * numSac)
        }
      }

      const frequencyTime = date ? getTimeFrequencyDate(date) : null
      if (
        onlyKoColumns &&
        ColumnState.ko !==
          getColumnState(
            this._steps,
            this._inputData,
            numSac,
            this._report.last_col_index === numSac,
            this.getMaxIndexUsed() || 0,
            gridStore().isDisabled,
            RoleGrid.editor,
            this._isHistory,
          )
      ) {
        continue
      }

      for (let j = 0; j < this._steps.length; j++) {
        const step = this._steps[j]

        step.isHidden = !step.hidden
          ?.slice(startColumnIndex, endColumnIndex)
          .includes(false)

        const rowIndex = j
        this._cells.push(
          new cellClasses[step?.type](
            rowIndex,
            numSac,
            groupedInputs[`${numSac}-${rowIndex}`] || [],
            step,
            this._isHistory,
            this._onExport,
            this._report.id,
            this._steps,
          ),
        )
      }

      const headerParams = getHeaderComponentParams(
        this._steps,
        this._inputData,
        numSac,
        0,
        this._report.last_col_index,
        this._document.grid_header,
        this._document.trigger,
        this.getMaxIndexUsed() || 0,
        gridStore().isDisabled,
        alertsStore()?.getAlertsForReport(this._report.id),
        RoleGrid.editor,
        frequencyTime,
        this._isHistory,
      )

      this._columnsDefinition.push(
        this.buildColumn(numSac, headerParams, false),
      )
    }

    this._groupedCells = _.keyBy(
      this._cells,
      (cell: Cell) => `${cell.rowIndex}-${cell.colIndex}`,
    )
    return this
  }

  updateGridBody(pageNumber: number): this {
    if (!this._report)
      throw new Error('Error while building cells, Grid not found')
    if (!this._inputData || this._inputData.length === 0) {
      console.warn('No input data found, skipping grid update.')
      return this
    }
    const startUpdateColIndex = pageNumber * this._columnsPerPage

    let endColumnIndex = this._columnsPerPage + startUpdateColIndex
    endColumnIndex =
      endColumnIndex > this.reportGridSize()
        ? this.reportGridSize()
        : endColumnIndex

    const columnsToDisplay = this._columnsToDisplay.slice(
      startUpdateColIndex,
      endColumnIndex,
    )

    for (let j = 0; j < columnsToDisplay.length; j++) {
      const numSac = columnsToDisplay[j]

      const frequencyTime =
        this._columnsDefinition[j + this._shiftColumnIndex]
          ?.headerComponentParams?.frequencyTime

      const headerParams = getHeaderComponentParams(
        this._steps,
        this._inputData,
        numSac,
        0,
        this._report.last_col_index,
        this._document.grid_header,
        this._document.trigger,
        this.getMaxIndexUsed() || 0,
        gridStore().isDisabled,
        alertsStore()?.getAlertsForReport(this._report.id),
        RoleGrid.editor,
        frequencyTime,
      )

      this._columnsDefinition[j + this._shiftColumnIndex] = this.buildColumn(
        numSac,
        headerParams,
        false,
      )
    }
    const cellsToUpdate = this._cells.filter((cell) =>
      this._columnsToDisplay.includes(cell.colIndex),
    )

    this._groupedCells = _.keyBy(
      cellsToUpdate,
      (cell: Cell) => `${cell.rowIndex}-${cell.colIndex}`,
    )
    return this
  }

  updateColumn(currentEditedCellIndex: number): this {
    if (!this._report)
      throw new Error('Error while building cells, Grid not found')

    const frequency = this.document.frequency

    let date: Date | null = null

    if (frequency.enabled) {
      date = getReportStartingTime(frequency, this.report)
    }
    if (!this._inputData || this._inputData.length === 0) {
      console.warn('No input data found, skipping grid update.')
      return this
    }

    const frequencyTime = date
      ? `${date.getHours()}:${date.getMinutes()}`
      : null
    const headerParams = getHeaderComponentParams(
      this._steps,
      this._inputData,
      currentEditedCellIndex,
      0,
      this._report.last_col_index,
      this._document.grid_header,
      this._document.trigger,
      this.getMaxIndexUsed() || 0,
      gridStore().isDisabled,
      alertsStore()?.getAlertsForReport(this._report.id),
      RoleGrid.editor,
      frequencyTime,
    )

    if (
      this._columnsDefinition[currentEditedCellIndex + this._shiftColumnIndex]
    ) {
      this._columnsDefinition[currentEditedCellIndex + this._shiftColumnIndex] =
        this.buildColumn(currentEditedCellIndex, headerParams, false)
    }

    return this
  }

  buildRow(
    index: number,
    step: any,
    mappedTags: any[],
    details: any,
    aqlTags: any,
  ): JStep {
    const cellsWithDynamicHeightInPdf = [
      StepType.Text,
      StepType.List,
      StepType.Calculator,
    ]
    let maxCellHeight = 0
    if (this._onPdf && cellsWithDynamicHeightInPdf.includes(step.type)) {
      maxCellHeight = Math.max(
        ...step?.answers?.map((answer) => {
          let cellText = ''

          switch (step.type) {
            case StepType.Calculator:
              const inputData = this.inputData.find(
                (row) => row.id === answer.id,
              )
              cellText = replaceComputePlaceholders(
                step.calcul,
                inputData ?? answer,
                this._steps,
                2,
              )
              break
            case StepType.List:
              cellText = answer.value.join(', ')
              break
            default:
              cellText = answer.value
          }

          return getCellHeight(
            cellText,
            [StepType.Text, StepType.List].includes(step.type) ? 7 : 16,
          )
        }),
      )
    }

    const descriptionHeight = computeRowHeight({
      tags: mappedTags,
      name: step?.name,
      description: step?.description,
      descriptionHeight: step?.description_height,
      type: step?.type,
      onExport: this._onExport,
      onPdf: this._onPdf,
    })
    return {
      ...step,
      file: step.files_attached?.[0],
      details,
      index,
      hidden: step.hidden || Array(Number(this._report?.gridSize)).fill(false),
      isHidden: step.hidden?.includes(true),
      isMandatory: step.is_mandatory,
      isDisabled: step.disabled || false,
      colIdsToDisable: step.col_ids_to_disable || [],
      shiftIndex: this._shiftColumnIndex,
      tags: mappedTags,
      aqlTags,
      rowHeight: Math.max(descriptionHeight, maxCellHeight),
      separatorDecimal: getSeparator(usersStore().user.language, 'decimal'),
    }
  }

  buildColumn(index: number, headerParams: any, columnHide: boolean): object {
    const hasNoReportPermission = !acl.can('edit-report', [
      this._document,
      this._report,
      this._masterSession,
      this._session,
    ])
    return {
      headerClass: 'header',
      headerComponent: CustomHeader,
      headerComponentParams: {
        ...headerParams,
        frequency: this._document.frequency,
      },
      field: headerParams.name,
      gridType: RoleGrid.conceptor,
      reportId: this._onExport ? this._report.id : null,
      cellDataType: false,
      realIndex: index,
      width: this._onPdf ? PDF_CELL_WIDTH : undefined,
      index: index + this._shiftColumnIndex,
      editable: (params: any, isNativeCellDisabled = true) => {
        return isEditable(
          params,
          index,
          usersStore().user.id,
          this._inputData,
          this._document,
          this._role,
          this._report,
          isNativeCellDisabled,
          hasNoReportPermission,
          this._isHistory,
        )
      },
      cellStyle: (params: any) => {
        return getCellStyle(
          params,
          this._report,
          this._isHistory,
          index,
          this._highlightedCols,
          this._pinnedColumnIndex + this._shiftColumnIndex,
          this.onPdf,
        )
      },
      cellClassRules: this.ragCellClassRules,
      pinned:
        this._pinnedColumnIndex ===
        this._columnsDefinition.length - this._shiftColumnIndex,
      colId: index + this._shiftColumnIndex,
      suppressMovable: true,
      hide: columnHide,
      cellRendererSelector: (params: any) => {
        return getCellRendererSelector(params)
      },
      cellEditorSelector: (params: any) => {
        return getCellEditorSelector(params, false)
      },
    }
  }

  updateSampling(rowIndex: number, colId: number): this {
    const step = this._steps.find((step) => step.num_step === rowIndex + 1)
    const isRecomputeSampling = this._currentSite?.flags?.sampling?.recompute

    if (step?.last_sampling_areas) {
      const latestSamplingArea = step?.last_sampling_areas as any
      if (latestSamplingArea[colId] === false) {
        if (isRecomputeSampling && step.details?.compute_sampling) {
          const refreshedSampling = refreshSampling(
            latestSamplingArea,
            step.frequency,
            step.sample,
            colId,
            colId + this._report?.gridSize,
            this._shiftColumnIndex,
            this._report?.gridSize,
          )
          if (refreshedSampling && refreshedSampling.length > 0) {
            step.last_sampling_areas = _.clone(refreshedSampling)
            loggerHelper.logEvent(
              `Sampling has been forced and recomputed.\n Report id : ${this._report.id}\n col id : ${colId}\n row id : ${rowIndex}\n Frequency : ${step.frequency}\n Sample : ${step.sample}\n Previous sampling : ${latestSamplingArea}\n new sampling : ${refreshedSampling}`,
            )
          }
        } else if (
          latestSamplingArea &&
          (!isRecomputeSampling || !step?.details?.compute_sampling)
        ) {
          if (step) step.last_sampling_areas[colId] = true
          loggerHelper.logEvent(
            `Sampling has been forced without recomputing.\n Report id : ${this._report.id}\n col id : ${colId}\n row id : ${rowIndex}\n Frequency : ${step.frequency}\n Sample : ${step.sample}\n Previous sampling : ${latestSamplingArea}\n new sampling : ${latestSamplingArea}`,
          )
        }
      }
    }
    return this
  }

  addColumn = async () => {
    loggerHelper.logInfo('operator report report-multiple AddColumn function')
    const newColIndex = Number(this.reportGridSize()) + this._shiftColumnIndex
    const newColIndexOffshift = newColIndex - this._shiftColumnIndex
    // state.disableDelete = false
    const maxIndexUsed = this.getMaxIndexUsed() || 0

    // Refresh sampling for all rows
    this._steps.forEach((step: JStep) => {
      if (step?.details?.samplingAreas) {
        if (
          typeof step.last_sampling_areas[newColIndexOffshift] === 'undefined'
        ) {
          let refreshedSampling: boolean[] = []
          if (
            step.repetitions.some(
              (repetition) =>
                repetition.repetition_type === RepetitionType.frequency,
            )
          ) {
            refreshedSampling = computeFrequency(
              step.repetitions[0].value,
              0,
              newColIndex + 1,
            )
          } else {
            refreshedSampling = addSampling(
              step.last_sampling_areas,
              step.frequency,
              step.sample,
              newColIndex,
              newColIndex + 1,
              step.details.updatedShiftIndex,
            )
          }
          if (refreshedSampling && refreshedSampling.length > 0)
            step.last_sampling_areas = refreshedSampling
        }
      }
      if (step.last_targets) {
        const lastTarget = _.last(step.last_targets) as JStepLastTargets
        if (lastTarget) lastTarget.last_col_id = lastTarget.last_col_id + 1
      }

      const rowIndex = step.num_step - 1

      this._cells.push(
        new cellClasses[step?.type](
          rowIndex,
          newColIndexOffshift,
          [],
          step,
          this._isHistory,
          this.onExport,
          this.report.id,
          this._steps,
        ),
      )
    })

    this._report.status = ReportStatus.in_progress
    await setReportStatus({
      report: this._report,
      status: ReportStatus.in_progress,
    })

    const columnDefs = this._columnsDefinition
    let date = null
    const frequency = this.document.frequency
    if (frequency?.enabled) {
      const startingTime = getReportStartingTime(frequency, this.report)

      date = getNthColumnDate(
        this.document.frequency,
        startingTime,
        newColIndexOffshift,
      )
    }

    const headerParams = getHeaderComponentParams(
      this._steps,
      this._inputData,
      newColIndexOffshift,
      0,
      this._report.last_col_index,
      this._document.grid_header,
      this._document.trigger,
      maxIndexUsed,
      false,
      alertsStore()?.getAlertsForReport(this._report.id),
      RoleGrid.editor,
      date,
    )

    columnDefs.push(this.buildColumn(newColIndexOffshift, headerParams, false))

    this._columnsDefinition = [...columnDefs]

    this._columnsToDisplay.push(newColIndexOffshift)

    this._report.gridSize++
  }

  setPinnedColumn = (columnToPin = null) => {
    loggerHelper.logInfo(
      'operator report report-multiple setPinnedColumn function',
    )

    if (columnToPin !== null) this._pinnedColumnIndex = columnToPin
    else this._pinnedColumnIndex = null

    const column = this._columnsDefinition?.find((column: any) => {
      return column?.index - this._shiftColumnIndex === columnToPin
    })
    if (!column) return
    column.pinned = !column.pinned
  }

  setNewTargetHeader = (newTargetColId: number) => {
    loggerHelper.logInfo(
      'operator report report-multiple setNewTargetHeader function',
    )
    this._highlightedCols = []
    this._steps?.forEach((step) => {
      if (step?.last_targets?.length)
        step.last_targets
          .map((e) => e.init_col_id)
          .forEach((colId) => this._highlightedCols.push(colId))
    })

    const column = this._columnsDefinition?.find((column: any) => {
      return column?.index - this._shiftColumnIndex === newTargetColId
    }) as any

    if (!column) return

    column.cellStyle = (params: any) => {
      return getCellStyle(
        params,
        this._report,
        this._isHistory,
        newTargetColId,
        this._highlightedCols,
        this._pinnedColumnIndex + this._shiftColumnIndex,
        this.onPdf,
      )
    }
  }

  setNewAlertHeader = (colId: number) => {
    const column = this._columnsDefinition?.find((column: any) => {
      return column?.index - this._shiftColumnIndex === colId
    }) as any

    if (!column) return
    const alerts = alertsStore()?.getAlertsForReport(this._report.id)
    column.headerComponentParams.alerts = alerts?.filter((documentAlert) =>
      documentAlert.erroredSteps.some(
        (erroredStep) => erroredStep.answer.colId === colId,
      ),
    )
    column.headerComponentParams.alert = column.headerComponentParams.alerts[0]
    column.hide = false
  }

  unhideCol = async (numSac: number) => {
    const colIdToDisplay = numSac - 1
    const insertIndex = this._columnsToDisplay.findIndex(
      (colId) => colId > colIdToDisplay,
    )
    if (!this._columnsToDisplay.includes(colIdToDisplay)) {
      if (insertIndex === -1) {
        this._columnsToDisplay.push(colIdToDisplay)
      } else {
        this._columnsToDisplay.splice(insertIndex, 0, colIdToDisplay)
      }
    }

    const pageIndex =
      Math.floor(
        this._columnsToDisplay.findIndex((e) => e === colIdToDisplay) /
          this._columnsPerPage,
      ) ?? 0
    await this.prepareGrid(false, pageIndex)
  }

  removeColumn = () => {
    loggerHelper.logInfo(
      'operator report report-multiple removeColumn function',
    )

    this._columnsDefinition.pop()
    this._columnsToDisplay.pop()
    this._report.gridSize--
  }

  addInputData(inputData: JInputData): void {
    const newInput = _.cloneDeep(inputData)

    this._inputData.unshift(newInput)
    const cell = this.getCellByIndex(newInput.row_id, newInput.col_id)
    cell?.addInputData(newInput)
  }

  updateInputData(updatedInputData: JInputData): void {
    const index = this._inputData.findIndex(
      (e) =>
        e.col_id === updatedInputData.col_id &&
        e.row_id === updatedInputData.row_id,
    )

    if (index !== -1) {
      this._inputData[index] = updatedInputData
    }

    const cell = this.getCellByIndex(
      updatedInputData.row_id,
      updatedInputData.col_id,
    )
    cell?.updateInputData(updatedInputData)
  }

  reportGridSize(): number {
    return this._report.gridSize
  }

  lastColumnIndex() {
    return this._cells.length
  }

  steps(): JStep[] {
    return this._steps
  }

  columnsDefinition(): object[] {
    return this._columnsDefinition
  }

  rowsDefinition(): object[] {
    return this._rowsDefinition
  }

  cellsDefinition(): Cell[] {
    return this._cells
  }

  listOptions(): ListOptions[] {
    return this._listOptions
  }

  columnsToDisplay() {
    return this._columnsToDisplay
  }

  get onPdf(): boolean {
    return this._onPdf
  }

  getCellByIndex(rowIndex: number, colIndex: number): Cell | undefined {
    return this._cells.find(
      (c) => c.rowIndex === rowIndex && c.colIndex === colIndex,
    )
  }

  getReport(): JReport {
    return this._report
  }

  get report(): JReport {
    return this._report
  }

  get isWorkplaceDoc(): boolean {
    return this._isWorkplaceDoc
  }

  get isHistory(): boolean {
    return this._isHistory
  }

  get inputData(): JInputData[] {
    return this._inputData
  }

  getDocument(): JDocument {
    return this._document
  }

  getMasterSession(): any {
    return this._masterSession
  }

  getSession(): JSession {
    return this._session
  }

  getShiftColumnIndex(): number {
    return this._shiftColumnIndex
  }

  getMaxIndexUsed(): number {
    return this._inputData?.length
      ? Math.max(...this._inputData?.map((data) => data.col_id))
      : 0
  }

  columnsPerPage(): number {
    return this._columnsPerPage
  }

  isJustifyKoEnabled(): boolean {
    return this._isJustifyKoEnabled
  }

  // getShowHistoryModal(): boolean {
  //   return this._cells.some(c => c.getShowHistoryModal())
  // }

  // getShowJustificationModal(): boolean {
  //   return this._cells.some(c => c.getShowJustificationModal())
  // }

  // setShowJustificationModal(showJustificationModal): boolean {
  //   return this._showJustificationModal = showJustificationModal
  // }

  // get showJustificationModal(): boolean {
  //   return this._showJustificationModal
  // }

  get lastColIndexUsed() {
    return this._lastColIndexUsed
  }
  setLastColIndexUsed(value: number): void {
    this._lastColIndexUsed = value
  }

  setInitialGridSize(value): void {
    this._report.initialGridSize = value
  }

  get rowDefinition(): any[] {
    return this._rowsDefinition
  }

  get onExport(): boolean {
    return this._onExport
  }

  set onExport(value: boolean) {
    this._onExport = value
  }
}

export default Grid
